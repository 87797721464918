import { useFetchSiteCoreReport } from '@repositories/reports';

import { SitecoreReportTypeEnum } from '@typings/sitecore';

export const useFetchMembershipFoundationReports = () => {
  const districtReport = useFetchSiteCoreReport(
    SitecoreReportTypeEnum.District
  );
  const individualReport = useFetchSiteCoreReport(
    SitecoreReportTypeEnum.Individual
  );
  const clubReport = useFetchSiteCoreReport(SitecoreReportTypeEnum.Club);

  const regionReport = useFetchSiteCoreReport(SitecoreReportTypeEnum.Region);

  const grantsReport = useFetchSiteCoreReport(SitecoreReportTypeEnum.Grants);

  const isLoading =
    districtReport.loading ||
    individualReport.loading ||
    clubReport.loading ||
    regionReport.loading ||
    grantsReport.loading;

  return {
    reports: {
      [SitecoreReportTypeEnum.District]: districtReport.data,
      [SitecoreReportTypeEnum.Individual]: individualReport.data,
      [SitecoreReportTypeEnum.Club]: clubReport.data,
      [SitecoreReportTypeEnum.Region]: regionReport.data,
      [SitecoreReportTypeEnum.Grants]: grantsReport.data,
    },
    isLoading,
  };
};
