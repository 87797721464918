import React from 'react';

import classNames from 'classnames';

interface Props {
  desktopOnly?: boolean;
  size?: number | null;
  classname?: string;
}

const Divider: React.FC<Props> = ({ desktopOnly, size, classname }) => {
  const value = size ? 8 : 16;
  return (
    <hr
      className={classNames(
        `border-t-0 border-b ${
          desktopOnly ? 'border-transparent' : 'border-gray-300'
        } w-full mt-6 desktop:mt-${value} mb-6`,
        { 'desktop:border-gray-300': desktopOnly },
        classname
      )}
    />
  );
};

export default Divider;
