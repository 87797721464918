import React from 'react';

import GenerateReportAccordion from '@presenters/web/components/GenerateReportAccordion';

import { columnWiseReportData } from '@utils/memberShipFoundationDistrictReport';

import { useTranslation } from '@external/react-i18next';

import { GetSitecoreReportQuery } from '@typings/operations';

export const ReportAccordion = ({
  sitecoreReportsData,
  title,
  titleLabel,
}: {
  sitecoreReportsData: GetSitecoreReportQuery['reportData'];
  title: string;
  titleLabel: string;
}) => {
  const columnWiseData = columnWiseReportData(sitecoreReportsData);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-naturals-gray-600 font-semibold text-3lg mobile-m:text-xl tablet:text-xl-40 leading-xl-48 mb-0">
        {t(title, titleLabel)}
      </h2>
      <div className="flex flex-col w-full tablet:gap-15">
        {columnWiseData.map((reportsArray, index) => (
          <div
            key={`${title}-section-${index + 1}`}
            className="w-full flex flex-col tablet:flex-row tablet:gap-15"
          >
            {reportsArray.map(report => (
              <div
                key={report.label}
                className="w-full tablet:w-1/2 flex flex-col gap-2 mt-8 first:mt-0 last:mb-8 tablet:mb-0 tablet:mt-0"
              >
                <div className="text-naturals-gray-600 text-lg tablet:text-lg-24 font-semibold">
                  {report.label}
                </div>
                <GenerateReportAccordion items={report.children} />
              </div>
            ))}
            {reportsArray.length % 2 !== 0 && (
              <div className="w-full tablet:w-1/2 flex flex-col" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
